type ModuleConfig = {
  monitorStockAvailability: boolean;
  showStockAvailability: boolean;
};

type EnvConfig = {
  rumApiKey: string | undefined;
};

type Config = EnvConfig & ModuleConfig;

const allConfigs: Record<string, ModuleConfig> = {
  mapo: {
    monitorStockAvailability: false,
    showStockAvailability: false,
  },
  pht: {
    monitorStockAvailability: true,
    showStockAvailability: true,
  },
};

const getModuleConfig = (projectName: string) => allConfigs[projectName] || {};

if (!process.env.RAZZLE_PROJECT_NAME) {
  console.error('PROJECT_NAME is not defined');
}

const knownProjects = Object.keys(allConfigs);
const projectName = process.env.RAZZLE_PROJECT_NAME || '';

if (!knownProjects.includes(projectName)) {
  console.error(`PROJECT_NAME "${projectName}" is not known. Known projects are:`, knownProjects.join(', '));
}

const config: Config = {
  rumApiKey: process.env.RAZZLE_RUM_API_KEY,
  ...getModuleConfig(projectName),
};

export const { rumApiKey, monitorStockAvailability, showStockAvailability } = config;
