import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownTwoTone';
import ArrowRightIcon from '@material-ui/icons/ArrowRightTwoTone';
import Skeleton from '@material-ui/lab/Skeleton';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useRecoilValueLoadable } from 'recoil';
import { catalogueGlobalFilterState } from '../../../api/products/catalogue';
import { localeState } from '../../../api/appState';
import { mainNavigationIsNodeActiveSelector, mainNavigationNodeSelector } from '../../../api/mainNavigation';

export interface MainNavigationContentItemTreeProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  classes?: Partial<Record<MainNavigationContentItemTreeClassKey, string>>;
  className?: string;
  icon?: React.ReactElement;
  level?: number;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
  to: string;
}

export type MainNavigationContentItemTreeClassKey =
  | 'root'
  | 'itemAllNextLevels'
  | 'button'
  | 'buttonZero'
  | 'buttonZeroActive'
  | 'buttonLabel'
  | 'buttonLabelPrimary'
  | 'buttonLabelSecondary'
  | 'buttonIcon'
  | 'wrapperZero'
  | 'wrapperAllNextLevels'
  | 'last';

const themeSettings = { name: 'MainNavigationContentItemTree' };
const defaultBorderRadius = '13px';
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentItemTreeClassKey, {}>({
      root: {
        padding: theme.spacing(0.5, 2, 0.5, 2),
        border: '1px solid transparent',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: defaultBorderRadius,
      },
      itemAllNextLevels: {
        padding: theme.spacing(0.5, 0),
      },

      button: {
        width: '100%',
        height: theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        cursor: 'pointer',
      },
      buttonZero: {
        backgroundColor: '#002969',
        color: '#FFFFFF',
        borderRadius: defaultBorderRadius,
      },
      buttonZeroActive: {
        backgroundColor: '#002969',
        marginBottom: '15px',
        color: 'white',
        '& > div > svg': {
          fill: 'white',
        },
      },
      last: {
        backgroundColor: '#fff',
      },

      wrapperZero: {
        backgroundColor: '#a6a6a6',
        borderRadius: defaultBorderRadius,
      },

      wrapperAllNextLevels: {
        backgroundColor: 'rgba(255,255,255,0.35)',
        borderRadius: defaultBorderRadius,
      },

      buttonLabel: {},
      buttonLabelPrimary: {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightMedium,
      },
      buttonLabelSecondary: {},
      buttonIcon: {
        minWidth: 32,
        color: '#000',
      },
    }),
  themeSettings,
);

const MainNavigationContentItemTree = React.forwardRef<HTMLDivElement, MainNavigationContentItemTreeProps>(
  ({ children, classes: pClasses, className, icon, level = 0, primary, secondary, to, ...rest }, ref) => {
    const t = useTranslate();
    const classes = useStyles({ classes: pClasses });
    const history = useHistory();

    const toWithoutLocale = to.slice(3);

    const match = useRouteMatch();
    const locale = useRecoilValue(localeState);
    const setFilter = useSetRecoilState(catalogueGlobalFilterState);
    const { active, last } = useRecoilValue(mainNavigationIsNodeActiveSelector({ nodePath: toWithoutLocale, match }));

    const nodeLoadable = useRecoilValueLoadable(mainNavigationNodeSelector(toWithoutLocale));

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
      if (active) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }, [active]);

    const handleClick: (event: React.MouseEvent<HTMLDivElement>) => void = (event) => {
      event.preventDefault();
      if (history.location.pathname === to) setOpen(open ? false : true);
      if (history.location.pathname !== to) {
        history.push(to);
        setFilter({ searchText: undefined });
      }
    };

    if (nodeLoadable.state === 'hasError') {
      console.error('MainNavigationContentItemTree node loading error.', nodeLoadable.contents);
      return null;
    }

    if (nodeLoadable.state === 'loading') {
      return (
        <MainNavigationContentItemTreeInner2
          ref={ref}
          active={active}
          classes={classes}
          isExpandable
          level={level}
          onClick={handleClick}
          primary={!primary ? <Skeleton variant='text' width={120} /> : primary}
          // secondary={secondary}
          {...rest}
        />
      );
    }

    const node = nodeLoadable.contents;

    if (!node) return null;

    return (
      <>
        <MainNavigationContentItemTreeInner2
          ref={ref}
          last={last && !node.children?.length}
          active={active}
          classes={classes}
          isExpandable={!!node.children}
          level={level}
          onClick={handleClick}
          primary={t(node.label, { acceptString: true }) || node?.label?.[0]?.text || ''}
          secondary={secondary}
          {...rest}
        >
          {node.children &&
            active &&
            open &&
            node.children?.map((ch, idx) => (
              <MainNavigationContentItemTree key={idx.toString()} to={`/${locale}${ch.code}`} level={level + 1} />
            ))}
        </MainNavigationContentItemTreeInner2>
      </>
    );
  },
);

MainNavigationContentItemTree.displayName = 'MainNavigationContentItemTree';

export default withThemeProps(themeSettings)(MainNavigationContentItemTree);

interface MainNavigationContentItemTreeInnerProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  children?: React.ReactNode;
  classes: Record<MainNavigationContentItemTreeClassKey, string>;
  className?: string;
  isExpandable: boolean;
  last?: boolean;
  level?: number;
  onClick: (event: React.MouseEvent<any>) => void;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
}

const MainNavigationContentItemTreeInner2 = React.forwardRef<HTMLDivElement, MainNavigationContentItemTreeInnerProps>(
  (
    {
      active,
      children,
      classes,
      className,
      isExpandable,
      onClick: handleClick,
      primary,
      secondary,
      level = 0,
      last,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          classes.root,
          { [classes.itemAllNextLevels]: level > 0 /* [classes.subitemNextLevel]: level > 1 */, [classes.last]: last },
          className,
        )}
        {...rest}
      >
        <div
          className={clsx(classes.button, {
            [classes.buttonZero]: level === 0,
            [classes.buttonZeroActive]: level === 0 && active,
          })}
          aria-hidden='true'
          onClick={handleClick}
        >
          <div className={classes.buttonIcon}>
            {isExpandable && active && <ArrowDropDownIcon />}
            {isExpandable && !active && <ArrowRightIcon />}
          </div>
          <div className={classes.buttonLabel}>
            {primary && <span className={classes.buttonLabelPrimary}>{primary}</span>}
            {secondary && <span className={classes.buttonLabelSecondary}>{secondary}</span>}
          </div>
        </div>
        {isExpandable && active && children && (
          <div className={clsx({ [classes.wrapperZero]: level === 0, [classes.wrapperAllNextLevels]: level > 0 })}>
            {children}
          </div>
        )}
      </div>
    );
  },
);

MainNavigationContentItemTreeInner2.displayName = 'MainNavigationContentItemTreeInner';

// const MainNavigationContentItemTreeInner = React.forwardRef<HTMLDivElement, MainNavigationContentItemTreeProps>(
//   ({ children, className, classes: pClasses, icon, to }, ref) => {
//     const t = useTranslate();
//     const classes = useStyles({ classes: pClasses });
//     const history = useHistory();

//     // const match = useRouteMatch();

//     // React.useEffect(() => {
//     //   const re = pathToRegexp(match.path, undefined, { strict: match.isExact });
//     //   const a = re.exec(to);

//     //   return a !== null;
//     // }, [])

//     const [isExpanded, toggleExpanded] = useRecoilState(mainNavigationNodeExpansionState(to));
//     const node = useRecoilValue(mainNavigationNodeSelector(to));

//     if (!node) return null;

//     const isExpandable = !!(node.children && node.children.length);
//     const active = false;

//     return (
//       <ListItem ref={ref} className={clsx(classes.root, className)} component='div' button>
//         <div
//           className={clsx(classes.content, { [classes.active]: active })}
//           aria-hidden='true'
//           onClick={(event: React.MouseEvent) => {
//             event.preventDefault();

//             toggleExpanded(undefined);

//             if (!isExpanded) {
//               history.push(to);
//             }
//           }}
//         >
//           {isExpandable && isExpanded && (
//             <ListItemIcon className={classes.icon}>
//               <ArrowDropDownIcon />
//             </ListItemIcon>
//           )}
//           {isExpandable && !isExpanded && (
//             <ListItemIcon className={classes.icon}>
//               <ArrowRightIcon />
//             </ListItemIcon>
//           )}
//           {!isExpandable && !isExpanded && <ListItemIcon className={classes.icon} style={{ width: 32 }} />}
//           <ListItemText
//             classes={{ primary: classes.label }}
//             primary={t(node.label, { acceptString: true })} /* secondary={secondary} */
//           />
//         </div>
//         {isExpandable &&
//           isExpanded &&
//           node?.children?.map((ch, idx) => <MainNavigationContentItemTree key={idx.toString()} to={ch.code} />)}
//       </ListItem>
//     );
//   },
// );
