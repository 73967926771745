import React from 'react';
// @ts-ignore
import GlobalizeProvider from '@sprinx/react-globalize/GlobalizeProvider';
// import loadable from '@loadable/component';
// import ComponentLoader from './AppComponentLoader';
import CldrLocaleCs from '@sprinx/react-globalize/CldrLocaleCs';
import CldrLocaleEn from '@sprinx/react-globalize/CldrLocaleEn';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import MessagesCs from './MessagesCs';
import MessagesEn from './MessagesEn';
import { localeState } from './api/appState';
import { LOCALES } from './i18n/constants';
import { SupportedLocale } from './i18n/types';

// CLDRS
// const CldrLocaleCs = loadable(() => import('@sprinx/react-globalize/CldrLocaleCs'), { fallback: <ComponentLoader /> });

// Messages
// const MessagesCs = loadable(() => import('./MessagesCs'), { fallback: <ComponentLoader /> });

export interface AppGlobalizeProps {
  children: React.ReactNode;

  /**
   * Defaultni jazyk.
   */
  // defaultLocale?: string;

  // /**
  //  * Handler zmeny preferovaneho jazuku.
  //  *
  //  * Signatura: `(languageCode) => void`
  //  */
  // onPreferredLanguageChanged?: (languageCode: string) => void;

  // supportedLocales: string[] | { code: string }[];
}

const AppGlobalize: React.FC<AppGlobalizeProps> = ({
  children,
  // defaultLocale = 'en',
  // onPreferredLanguageChanged,
  // supportedLocales,
}) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const history = useHistory();

  return (
    <GlobalizeProvider
      dontResolveLocale
      cldrs={{ cs: CldrLocaleCs, en: CldrLocaleEn }}
      messages={{ cs: MessagesCs, en: MessagesEn }}
      defaultLocale={locale}
      supportedLocales={LOCALES || ['cs']}
      onPreferredLocaleChanged={(newLocale) => {
        setLocale(newLocale as SupportedLocale);
        const urlWithoutLocation = window.location.pathname.slice(3);
        const url = `/${newLocale}${urlWithoutLocation}`;
        history.push(url);
      }}
    >
      {children}
    </GlobalizeProvider>
  );
};

export default AppGlobalize;
