import { useTranslate } from '@sprinx/react-globalize';
import React from 'react';
import { Route } from 'react-router-dom';

export default function NotFoundErrorPage(): JSX.Element {
  const t = useTranslate();

  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = 404;
        return <div>{t('notFoundPage/text')}</div>;
      }}
    />
  );
}
