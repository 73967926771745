import Link from '@material-ui/core/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import React from 'react';
import clsx from 'clsx';
import { useTranslate } from '@sprinx/react-globalize';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import { isAuthenticatedState } from '../../api/appState';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';

export type NavigationLinksProps = StandardDivProps<NavigationLinksClassKey>;

export type NavigationLinksClassKey = 'root' | 'navLink' | 'linkActive';

const themeSettings = { name: 'NavigationLinks' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<NavigationLinksClassKey, {}>({
      root: {},
      navLink: {
        fontSize: '16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        textDecoration: 'none',
        color: theme.palette.appBar.color.contrastText,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      linkActive: {
        textDecoration: 'underline',
      },
    }),
  themeSettings,
);

const NavigationLinks = React.forwardRef<HTMLDivElement, NavigationLinksProps>(
  ({ className, classes: pClasses }, ref) => {
    const routeUrlFactory = useRouteUrlFactory();
    const t = useTranslate();
    const classes = useStyles({ classes: pClasses });
    const isAuthenticated = useRecoilValue(isAuthenticatedState);
    const location = useLocation();

    const isActiveLink = (path: string) => location.pathname === path;

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <Link
          component={RouterLink}
          to={routeUrlFactory('homePage')}
          color='textPrimary'
          variant='inherit'
          className={clsx(classes.navLink, { [classes.linkActive]: isActiveLink(routeUrlFactory('homePage')) })}
          noWrap
        >
          {t('mainNavigation/home')}
        </Link>
        <Link
          component={RouterLink}
          to={routeUrlFactory('catalogue')}
          color='textPrimary'
          variant='inherit'
          className={clsx(classes.navLink, { [classes.linkActive]: isActiveLink(routeUrlFactory('catalogue')) })}
          noWrap
        >
          {t('mainNavigation/products')}
        </Link>
        <Link
          href='https://stary.magg.cz/katalognaradi'
          color='textPrimary'
          className={classes.navLink}
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('mainNavigation/ecatalogue')}
        </Link>
        <Link
          component={RouterLink}
          to={routeUrlFactory('termsAndConditions')}
          color='textPrimary'
          variant='inherit'
          className={clsx(classes.navLink, {
            [classes.linkActive]: isActiveLink(routeUrlFactory('termsAndConditions')),
          })}
          noWrap
        >
          {t('mainNavigation/tos')}
        </Link>
        <Link
          component={RouterLink}
          to={routeUrlFactory('contacts')}
          color='textPrimary'
          variant='inherit'
          className={clsx(classes.navLink, { [classes.linkActive]: isActiveLink(routeUrlFactory('contacts')) })}
          noWrap
        >
          {t('mainNavigation/contacts')}
        </Link>
        {isAuthenticated && (
          <Link
            component={RouterLink}
            to={routeUrlFactory('downloadDocuments')}
            color='textPrimary'
            variant='inherit'
            className={clsx(classes.navLink, {
              [classes.linkActive]: isActiveLink(routeUrlFactory('downloadDocuments')),
            })}
            noWrap
          >
            {t('mainNavigation/download')}
          </Link>
        )}
      </div>
    );
  },
);

NavigationLinks.displayName = 'NavigationLinks';

export default withThemeProps(themeSettings)(NavigationLinks);
