import { ApplicationSettings } from './@sprinx/react-after-razzle';
import { SupportedLocale } from './i18n/types';

const applicationSettings: ApplicationSettings = {
  currency: process.env.RAZZLE_SX_DEFAULT_CURRENCY || 'CZK',
  supportedCurrencies: ['CZK', 'EUR'],
  locale: (process.env.RAZZLE_SX_LOCALE as SupportedLocale) || 'cs',
  supportedLocales: ['cs', 'en'],
  pricesType: 'B2B',
  region: process.env.RAZZLE_SX_REGION || '_default',

  // TODO: add to AppProvider
  anonymouseUserId: process.env.RAZZLE_SX_ANONYMOUS_CONTACT_ID || 'anonymouse',
};

export default applicationSettings;
