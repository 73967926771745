import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useTranslate from '@sprinx/react-globalize/useTranslate';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import SelectField from '@sprinx/react-mui-fields/SelectField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { isMasterScopeState, useApiClient } from '../../api/appState';
import { deliveryPlacesForSlave } from '../../api/deliveryPlaces';
import {
  shoppingCustomerDeliveryPlacesSelector,
  shoppingCustomerSelector,
  shoppingSelectedDeliveryPlaceState,
} from '../../api/shopping';
import { shoppingCartState } from '../../api/shoppingCart';
import { getShippings, shippingsState } from '../../api/shipping/shipping';

export interface PickDeliveryPlaceProps extends StandardDivProps<PickDeliveryPlaceClassKey> {
  extraOnChange?: (value: any) => void;
  fullWidth?: boolean;
  required?: boolean;
}

export type PickDeliveryPlaceClassKey =
  | 'root'
  | 'fullWidth'
  | 'postaddress'
  | 'paper'
  | 'label'
  | 'helperText'
  | 'error'
  | 'selectField';

const themeSettings = { name: 'PickDeliveryPlace' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<PickDeliveryPlaceClassKey, {}>({
      root: {
        paddingBottom: theme.spacing(2),
      },
      selectField: {
        [theme.breakpoints.down('sm')]: {
          '& div': {
            whiteSpace: 'initial',
          },
        },
      },
      fullWidth: {
        width: '100%',
      },
      postaddress: {
        ...theme.typography.caption,
        paddingLeft: theme.spacing(2),
      },
      paper: {
        margin: theme.spacing(1, 0),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
      },
      label: {
        ...theme.typography.h6,
        color: theme.palette.primary.main,
      },
      helperText: {
        ...theme.typography.body2,
        color: theme.palette.text.hint,
        textAlign: 'left',
      },
      error: {
        color: theme.palette.error.main,
      },
    }),
  themeSettings,
);

const PickDeliveryPlace = React.forwardRef<HTMLDivElement, PickDeliveryPlaceProps>(
  ({ className, classes: pClasses, extraOnChange, fullWidth, required = false, ...rest }, ref) => {
    const apiClient = useApiClient();
    const t = useTranslate();
    const classes = useStyles({ classes: pClasses });
    const isMasterScope = useRecoilValue(isMasterScopeState);
    const deliveryPlacesLoadable = useRecoilValueLoadable(deliveryPlacesForSlave);
    const placesFromUser = useRecoilValue(shoppingCustomerDeliveryPlacesSelector);
    const [selectedPlace, updateDeliveryPlace] = useRecoilState(shoppingSelectedDeliveryPlaceState);
    const customer = useRecoilValue(shoppingCustomerSelector);
    const cart = useRecoilValue(shoppingCartState);

    const setShippings = useSetRecoilState(shippingsState);

    const places = React.useMemo(() => {
      if (deliveryPlacesLoadable.state === 'hasValue' && !isMasterScope)
        // @ts-ignore `getValue` is not typed
        return deliveryPlacesLoadable.getValue() || placesFromUser;
      return placesFromUser;
    }, [deliveryPlacesLoadable, isMasterScope, placesFromUser]);

    const handleChange = useCallback(
      async (e) => {
        updateDeliveryPlace(e);
        extraOnChange?.(e);
        if (!customer || !cart) return;
        const response = await getShippings(apiClient, customer, cart?.id);

        if (!response?.rows) return;
        setShippings(response.rows);
      },
      [updateDeliveryPlace, extraOnChange, customer, cart, apiClient, setShippings],
    );

    return (
      <div ref={ref} className={clsx(classes.root, { [classes.fullWidth]: fullWidth }, className)} {...rest}>
        <div className={clsx(classes.label, { [classes.error]: required && !selectedPlace })}>
          {t('app/pickDeliveryPlace/label')}
        </div>
        <div className={classes.paper}>
          <SelectField
            fullWidth
            onChange={handleChange}
            value={selectedPlace}
            required={required}
            className={classes.selectField}
          >
            {(places || []).map((l: any) => (
              <SelectFieldItem key={l.id} value={l}>
                {l.name}
                {l.postaddresses?.length ? (
                  <span className={classes.postaddress}>{` ${l.postaddresses[0].place.full}`}</span>
                ) : null}
              </SelectFieldItem>
            ))}
          </SelectField>
        </div>
        <div className={clsx(classes.helperText, { [classes.error]: required && !selectedPlace })}>
          {required && !selectedPlace
            ? t('app/pickDeliveryPlace/requiredError')
            : t('app/pickDeliveryPlace/helperText')}
        </div>
      </div>
    );
  },
);

PickDeliveryPlace.displayName = 'PickDeliveryPlace';

export default withThemeProps(themeSettings)(PickDeliveryPlace);
