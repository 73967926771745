import { asyncComponent, AsyncRouteProps } from '@jaredpalmer/after';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import { match as Match } from 'react-router-dom';
import Placeholder from './AppComponentLoader';
import { SupportedLocale } from './i18n/types';

/**
 * Standard routes builder...
 * @param locale
 * @param pathe
 * @param otherParamsToQs
 * @param inputParams
 */
export function routesBuilderDefaultdUrlBuilder(
  locale: SupportedLocale,
  path: string,
  otherParamsToQs: boolean | string[] | undefined | null,
  inputParams: Record<string, any> | undefined,
): string {
  const { pathParams, queryParams, otherParamsExists } = routesBuilderSplitInputParams(
    locale,
    otherParamsToQs,
    path,
    inputParams || {},
  );

  const toUrl = pathToRegexp.compile(path);
  const urlB = toUrl(pathParams);

  return !otherParamsToQs || !otherParamsExists
    ? safeAbsoluteUrl(path, urlB)
    : safeAbsoluteUrl(
        path,
        `${urlB}${qs.stringify(queryParams, {
          addQueryPrefix: true,
        })}`,
      );
}

export function pathToRouteUrlType(path: string): RouteUrlType | null {
  for (const key in routesDefinition) {
    const def = routesDefinition[key as RouteUrlType];
    if (typeof def.path !== 'object') continue;

    const paths = Object.values(def.path) as string[];
    if (paths.includes(path)) return key as RouteUrlType;
  }

  return null;
}

export const routesDefinition = {
  catalogue: {
    path: {
      cs: '/:locale/catalogue',
      en: '/:locale/catalogue',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  categories: {
    path: {
      cs: '/:locale/catalogue/:categories*',
      en: '/:locale/catalogue/:categories*',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  special: {
    path: {
      cs: '/:locale/special/:categories*',
      en: '/:locale/special/:categories*',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CataloguePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  product: {
    path: {
      cs: '/:locale/:sn([^/]+)-product-:id([^/]+)',
      en: '/:locale/:sn([^/]+)-product-:id([^/]+)',
    },
    component: asyncComponent({
      loader: () => import('./pages/ProductDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  bulkPurchase: {
    path: {
      cs: '/:locale/hromadny-nakup',
      en: '/:locale/bulk-purchase',
    },
    component: asyncComponent({
      loader: () => import('./pages/BulkPurchasePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  downloadDocuments: {
    path: {
      cs: '/:locale/dokumenty-ke-stazeni',
      en: '/:locale/documents-to-download',
    },
    component: asyncComponent({
      loader: () => import('./pages/DownloadDocumentsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartSummary: {
    path: {
      cs: '/:locale/cart/summary',
      en: '/:locale/cart/summary',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartSummaryPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  orderThankYou: {
    path: {
      cs: '/:locale/dekujeme-za-objednavku',
      en: '/:locale/thanks',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderThankYouPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  orderSentToApproval: {
    path: {
      cs: '/:locale/objednavka-odeslana-ke-schvaleni',
      en: '/:locale/order-sent-to-approval',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderSentToApprovalPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartsApproval: {
    path: {
      cs: '/:locale/profil/schvaleni-objednavek',
      en: '/:locale/profile/orders-approval',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartsApprovalPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  cartApproval: {
    path: {
      cs: '/:locale/profil/schvaleni-objednavek/:id',
      en: '/:locale/profile/orders-approval/:id',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CartApprovalPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfile: {
    path: {
      cs: '/:locale/profil',
      en: '/:locale/profile',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfilePage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileOrders: {
    path: {
      cs: '/:locale/profil/objednavky',
      en: '/:locale/profile/orders',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileOrdersPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileOrderDetail: {
    path: {
      cs: '/:locale/profil/objednavky/:id',
      en: '/:locale/profile/orders/:id',
    },
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileOrderDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileInvoices: {
    path: {
      cs: '/:locale/profil/faktury',
      en: '/:locale/profile/invoices',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileInvoicesPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileInvoiceDetail: {
    path: {
      cs: '/:locale/profil/faktury/:id',
      en: '/:locale/profile/invoices/:id',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileInvoiceDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileCreditNotes: {
    path: {
      cs: '/:locale/profil/dobropisy',
      en: '/:locale/profile/credit-notes',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileCreditNotesPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileCreditNoteDetail: {
    path: {
      cs: '/:locale/profil/dobropisy/:id',
      en: '/:locale/profile/credit-notes/:id',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileCreditNoteDetailPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileDocuments: {
    path: {
      cs: '/:locale/profil/dokumenty',
      en: '/:locale/profile/documents',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileDocumentsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  customerProfileShoppingLists: {
    path: {
      cs: '/:locale/profil/nakupni-seznamy',
      en: '/:locale/profile/nakupni-seznamy',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/CustomerProfileShoppingListsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  homePage: {
    path: {
      cs: '/:locale/home',
      en: '/:locale/home',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/HomePageNew'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  gdprPage: {
    path: {
      cs: '/:locale/gdpr',
      en: '/:locale/gdpr',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/GdprPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  termsAndConditions: {
    path: {
      cs: '/:locale/obchodni-podminky',
      en: '/:locale/terms-and-conditions',
    },
    component: asyncComponent({
      loader: () => import('./pages/TermAndConditionPageNew'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  news: {
    path: {
      cs: '/:locale/aktuality',
      en: '/:locale/news',
    },
    component: asyncComponent({
      loader: () => import('./pages/NewsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  career: {
    path: {
      cs: '/:locale/kariera',
      en: '/:locale/career',
    },
    component: asyncComponent({
      loader: () => import('./pages/CareerPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  contacts: {
    path: {
      cs: '/:locale/kontakty',
      en: '/:locale/contacts',
    },
    component: asyncComponent({
      loader: () => import('./pages/ContactsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  downloads: {
    path: {
      cs: '/:locale/ke-stazeni',
      en: '/:locale/downloads',
    },
    component: asyncComponent({
      loader: () => import('./pages/DownloadsPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  login: {
    path: {
      cs: '/:locale/prihlaseni',
      en: '/:locale/prihlaseni',
    },
    component: asyncComponent({
      loader: () => import('./pages/LoginPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  logout: {
    path: {
      cs: '/:locale/odhlaseni',
      en: '/:locale/logout',
    },
    component: asyncComponent({
      loader: () => import('./pages/LogoutPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  passwordRenewal: {
    path: {
      cs: '/:locale/zmena-hesla',
      en: '/:locale/change-password',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/PasswordRenewal'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
  orderDisapprovalSending: {
    path: {
      cs: '/:locale/objednavka-zrusena',
      en: '/:locale/order-cancelled',
    },
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/OrderDisapprovalSendingPage'),
      Placeholder,
    }),
    urlBuilder: routesBuilderDefaultdUrlBuilder,
  },
} as const;
/**
 * Returnd builded URL from `type` and params...
 *
 * ```
 * routeUrl('home', {locale, params: { ... } }); // => '/'
 * routeUrl('productDetail', {locale, params: { ... } }); // => '/some-product-name-product-12344'
 * ```
 */

export type RouteUrlType = keyof typeof routesDefinition;

type Sep = `/` | `-` | '_' | '(' | ')' | '[' | ']' | '{' | '}' | '*' | '^';

type Split<
  T extends string,
  Separator extends string,
  Result extends string = '',
> = T extends `${infer Start}${Separator}${infer Rest}`
  ? Split<Rest, Separator, Result | Start>
  : T extends `${infer Last}`
  ? Result | Last
  : never;

type RejectWithSep<T extends string, Separator extends string> = T extends `${Separator}`
  ? never
  : T extends `:${infer Param}`
  ? Param
  : never;

type ExtractParams<T extends string> = RejectWithSep<Split<T, Sep>, Sep>;

export type ParamsDict<T extends string> = Record<ExtractParams<T>, string>;

export function routeUrl(
  type: RouteUrlType,
  opts: {
    locale: SupportedLocale;
    otherParamsToQs?: boolean | string[];
    params: Record<string, string>;
  },
): string {
  const path = routePath(type, opts);
  const def = routesDefinition[type];
  const inputParams = opts.params || {}; // ensure params exists

  return def.urlBuilder && typeof def.urlBuilder === 'function'
    ? def.urlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any)
    : routesBuilderDefaultdUrlBuilder(opts.locale, path, opts.otherParamsToQs, inputParams as any);
}

export function routeIsActive(
  type: keyof typeof routesDefinition,
  opts: {
    customMatcher?: (opts: {
      nodePath: string;
      pathRegexMatches: RegExpExecArray | null;
      routeRegexMatch: boolean;
    }) => boolean;
    locale: SupportedLocale;
    match: Match<any>;
  },
): boolean {
  const nodePath = routePath(type, opts);

  const re = pathToRegexp(opts.match.path, undefined, { strict: opts.match.isExact });
  const pathRegexMatches = re.exec(nodePath);
  const routeRegexMatch = pathRegexMatches !== null;

  return opts.customMatcher ? opts.customMatcher({ nodePath, pathRegexMatches, routeRegexMatch }) : routeRegexMatch;
}

/**
 * Returns a `path` from roteDefinitions by `type` and `opts.locale`.
 *
 * ```
 * routePath('home', { locale }); // => '/'
 * routePath('productDetail', { locale }); // => '/:sn([^/]+)-product-:id([^/]+)'
 * ```
 */
export function routePath(type: keyof typeof routesDefinition, opts: { locale: string }): string {
  const def = routesDefinition[type];
  return typeof def.path === 'string' ? def.path : def.path[opts.locale];
}

/**
 * Returns `inputParameters` object splitted to two separate
 * @param otherParamsToQs
 * @param path
 * @param inputParams
 */
export function routesBuilderSplitInputParams<P extends Record<string, any>>(
  locale: SupportedLocale,
  otherParamsToQs: boolean | string[] | undefined | null,
  path: string,
  inputParams: P,
): { otherParamsExists: boolean; pathParams: Record<string, any>; queryParams?: Record<string, any> } {
  const [pathParams, queryParams] = !otherParamsToQs
    ? [{ ...inputParams, locale }]
    : ((reg) => {
        const pathParamKeys = reg.keys.map((i) => i.name);
        return Object.entries(inputParams).reduce<[Record<string, any>, Record<string, any>]>(
          (acc, [k, v]) => {
            if (pathParamKeys.includes(k)) {
              return [{ ...acc[0], [k]: v }, acc[1]];
            }

            if (Array.isArray(otherParamsToQs) && !otherParamsToQs.includes(k)) {
              return acc;
            }

            return [acc[0], { ...acc[1], [k]: v }];
          },
          [{ locale }, {}],
        );
      })(pathToRegexp(path));

  const otherParamsExists = !!queryParams && Object.keys(queryParams).length > 0;

  return {
    otherParamsExists,
    pathParams,
    queryParams,
  };
}

/**
 * Returns all router routes definitions...
 */
export function routeAllRoutesToRouter(): AsyncRouteProps[] {
  const extr = ({ urlBuilder, path, ...rest }: RouteDefinition): Omit<AsyncRouteProps, 'path'> => rest;

  return Object.values(routesDefinition).reduce((acc, itm) => {
    if (typeof itm.path === 'string') return [...acc, { path: itm.path, ...extr(itm as any) }] as AsyncRouteProps[];
    return [...acc, ...Object.values(itm.path).map((path) => ({ path, ...extr(itm as any) }))] as AsyncRouteProps[];
  }, [] as AsyncRouteProps[]);
}

const safeAbsoluteUrl = (path: string, url: string): string =>
  `${path.startsWith('/') ? '/' : ''}${url.replace(/^\//, '')}`;

export interface RouteDefinition extends Omit<AsyncRouteProps, 'path'> {
  path: string | Record<SupportedLocale, string>;
  urlBuilder: (
    locale: SupportedLocale,
    path: string,
    otherParamsToQs: boolean | string[] | undefined | null,
    params: any,
  ) => string;
}
