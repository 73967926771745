import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useChangePreferredLocale, usePreferredLocale } from '@sprinx/react-globalize';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SupportedLocale } from '../../../i18n/types';

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'column',
    },
    langWrapper: {
      marginLeft: 0,
    },
    button: {
      color: 'black',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '1rem',
    },
  }),
);

const LanguageMenu: FC = () => {
  const styles = useStyles();
  const locale = usePreferredLocale();
  const onLocalChange = useChangePreferredLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (locale: SupportedLocale) => {
    setAnchorEl(null);
    onLocalChange(locale);
  };
  // const isAuthenticated = useRecoilValue(isAuthenticatedState);
  // const isCatalogue = location.pathname.includes('/cs/catalogue');

  return (
    <Box className={clsx(styles.langWrapper)}>
      <Button className={styles.button} aria-controls='simple-menu' aria-haspopup='true' onClick={handleClick}>
        {locale}
      </Button>
      <Menu id='simple-menu' anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleChange('cs')}>cs</MenuItem>
        <MenuItem onClick={() => handleChange('en')}>en</MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageMenu;
