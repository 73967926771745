import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartTwoTone';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { useTranslate } from '@sprinx/react-globalize';
import { useRecoilValue } from 'recoil';
import useRouteUrlFactory from '../../../hooks/useRouteUrlFactory';
import { isAuthenticatedState } from '../../../api/appState';
import NavigationLinks from '../../../components/NavigationLinks';
import MainNavigationContentItem from './MainNavigationContentItem';
import MainNavigationContentItemTree from './MainNavigationContentItemTree';

export type MainNavigationContentProps = StandardDivProps<MainNavigationContentClassKey>;

export type MainNavigationContentClassKey = 'root' | 'primary' | 'secondary' | 'termsOfServices';

const themeSettings = { name: 'MainNavigationContent' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: theme.spacing(6),
      },
      primary: {
        flexGrow: 1,
        overflow: 'none',
        paddingTop: theme.spacing(2),
      },
      secondary: {
        display: 'none',
        [theme.breakpoints.down('lg')]: {
          display: 'flex',
          flexDirection: 'column',

          gap: theme.spacing(2),
          margin: theme.spacing(2),
          padding: theme.spacing(2),
          borderRadius: '13px',
          backgroundColor: '#F7D417',
        },
      },
      termsOfServices: {
        color: '#303040',
        fontWeight: 600,
      },
    }),
  themeSettings,
);

const MainNavigationContent = React.forwardRef<HTMLDivElement, MainNavigationContentProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });
    const routeUrlFactory = useRouteUrlFactory();
    const isAuthenticated = useRecoilValue(isAuthenticatedState);
    const t = useTranslate();

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <div className={classes.primary}>
          {/* <MainNavigationContentItem icon={<HomeIcon />} primary={'Domov'} to='/' /> */}
          {/* <MainNavigationContentItem icon={<PriorityHighIcon />} primary={'Kampaně'} to='/special/campaign' /> */}
          {/* <MainNavigationContentItemTree primary={'Všechny produkty'} to={('/catalogue')} /> */}
          <MainNavigationContentItemTree primary={t('mainNavigation/allProducts')} to={routeUrlFactory('catalogue')} />

          {/* <MainNavigationContentItem icon={<AttachMoneyIcon />} primary={'Výprodej'} to='/special/sale' /> */}

          {/* <MainNavigationContentItem icon={<AnnouncementIcon />} primary={'Novinky'} to='/special/introduction' /> */}
          {isAuthenticated && (
            <MainNavigationContentItem
              icon={<AddShoppingCartIcon />}
              primary={t('mainNavigation/bulkPurchase')}
              to={routeUrlFactory('bulkPurchase')}
            />
          )}
          {isAuthenticated && (
            <MainNavigationContentItem
              icon={<ArrowDownwardIcon />}
              primary={t('mainNavigation/downloadDocuments')}
              to={routeUrlFactory('downloadDocuments')}
            />
          )}
        </div>
        <NavigationLinks className={classes.secondary} />
      </div>
    );
  },
);

MainNavigationContent.displayName = 'MainNavigationContent';

export default withThemeProps(themeSettings)(MainNavigationContent);
